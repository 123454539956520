import debounce from 'lodash/debounce';
import { RefObject, useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';
import { getScrollSnapPositions } from 'scroll-snap-api';

interface ScrollProps {
  directions: 'left' | 'right';
}

interface UseSlider {
  displayedArrows: Record<ScrollProps['directions'], boolean>;
  ref: RefObject<HTMLDivElement>;
  slide: (direction: ScrollProps['directions']) => void;
  slideTo: (index: number) => void;
}
const initialValue: UseSlider['displayedArrows'] = {
  left: true,
  right: true,
};

const useSlider = (deps: React.ReactNode): UseSlider => {
  const ref = useRef<HTMLDivElement>(null);
  const [scrollSnapPositions, setScrollSnapPositions] = useState<number[]>([]);
  const [displayedArrows, setDisplayedArrows] =
    useState<UseSlider['displayedArrows']>(initialValue);

  const slide = (direction: ScrollProps['directions']): void => {
    if (ref.current) {
      const isLeft = direction === 'left';
      const currentScrollPosition = ref.current.scrollLeft + (isLeft ? 2 : -2);
      const filtered = scrollSnapPositions.filter((v) => {
        return isLeft ? v > currentScrollPosition : v < currentScrollPosition;
      });

      // if filtered position is only one or less, it's edge of slider.
      const nextArrowsState = { ...initialValue };
      if (filtered.length <= 1) {
        const key = isLeft ? 'right' : 'left';
        nextArrowsState[key] = false;
      }
      setDisplayedArrows(nextArrowsState);

      const targetIndex = isLeft ? 0 : filtered.length - 1;
      return ref.current.scrollTo({
        left: filtered[targetIndex],
        behavior: 'smooth',
      });
    }
  };

  const slideTo = (index: number): void => {
    if (ref.current) {
      const nextArrowsState = { ...initialValue };
      if (index === 0) {
        nextArrowsState.left = false;
      } else if (index === scrollSnapPositions.length - 1) {
        nextArrowsState.right = false;
      }
      setDisplayedArrows(nextArrowsState);

      ref.current?.scrollTo({
        left: scrollSnapPositions[index],
        behavior: 'smooth',
      });
    }
  };

  const { width } = useWindowSize();
  // Get scroll initial snap positions and also when window size and / or deps are changed.
  useEffect(() => {
    const updateScrollSnapPositions = (): void => {
      if (ref.current) {
        setScrollSnapPositions(getScrollSnapPositions(ref.current)['x']);
      }
    };

    const debouncedUpdate = debounce(updateScrollSnapPositions, 200);

    updateScrollSnapPositions(); // Initial update
    debouncedUpdate(); // Initial debounced update

    const handleResize = (): void => {
      debouncedUpdate();
    };

    handleResize(); // Initial resize handling

    return () => debouncedUpdate.cancel();
  }, [deps, width]);

  // Depending on whether scroll is shown, toggle slider buttons.
  // Passing deps as a dependency to useEffect is needed to update the state
  // when the children are changed and reset status of the buttons.
  useEffect(() => {
    if (ref.current) {
      const { offsetWidth, scrollWidth, scrollLeft } = ref.current;
      const mostRight = scrollWidth - (offsetWidth + scrollLeft) == 0;
      const mostLeft = scrollLeft == 0;

      if (offsetWidth === scrollWidth) {
        setDisplayedArrows({
          left: false,
          right: false,
        });
      } else if (mostLeft) {
        setDisplayedArrows({
          left: false,
          right: true,
        });
      } else if (mostRight) {
        setDisplayedArrows({
          left: true,
          right: false,
        });
      } else {
        setDisplayedArrows({
          ...initialValue,
        });
      }
    }
  }, [deps, width]);

  return { displayedArrows, ref, slide, slideTo };
};

export default useSlider;
