import { CollectionResponse } from 'api/product';
import ProductCard, { CARD_TYPES } from 'components/product-card';
import ProductImpressionsTracker from 'components/product-impressions-tracker';
import Slider from 'components/slider';
import { CollectionSorting } from 'constants/collection';
import useMobile from 'hooks/common/use-mobile';
import { CollectionProduct } from 'models/collection/collection';
import {
  CollectionContext,
  CollectionProvider,
} from 'modules/collection/state';
import React from 'react';
import { SWRConfiguration } from 'swr';

interface ICollectionSectionProps {
  collection: string;
  elementNameTracking?: string[];
  filterSubscriptionEnabledItems?: boolean;
  isBlogPost?: boolean;
  isHomePage?: boolean;
  isSpecialLimitedSlider?: boolean;
  itemSize?: 'Normal' | 'Large' | undefined;
  lastSlide?: React.ReactNode;
  shouldShowLoading?: boolean;
  sliderSize?: 'default' | 'medium' | 'large';
  staticCollection?: CollectionResponse;
  swrOptions?: SWRConfiguration;
  onTileClick?: (arg?: CollectionProduct) => void;
}

/**
 * Generic slider for collection.
 * @TODO : Name is misleading as it is a generic component with a different design from CollectionSection (with displayAsSlider on)
 */
const CollectionSectionBestseller: React.FC<ICollectionSectionProps> = ({
  collection,
  elementNameTracking,
  filterSubscriptionEnabledItems,
  isBlogPost = false,
  isHomePage = false,
  itemSize,
  lastSlide,
  shouldShowLoading = false,
  sliderSize,
  staticCollection,
  swrOptions,
  onTileClick,
}) => {
  const isMobile = useMobile();
  return (
    <CollectionProvider
      collection={staticCollection}
      collectionHandle={collection || ''}
      sortBy={CollectionSorting.DEFAULT}
      swrOptions={swrOptions}
    >
      <CollectionContext.Consumer>
        {(val) => {
          /** Quick and dirty solution... should be refactered */
          if (shouldShowLoading && val.isValidating) {
            return (
              <Slider>
                {new Array(5).fill(null).map((_, index) => (
                  // adjusted to the size of product tile
                  <div
                    className="h-[329px] w-full animate-pulse bg-gray-600 opacity-40 lg:h-[392px]"
                    key={`loading-${index}`}
                  />
                ))}
              </Slider>
            );
          }

          if (!val.collection) {
            return '';
          }

          let products = val.collection.products;
          if (isBlogPost) {
            products = products.slice(0, 5);
          }
          if (isHomePage) {
            products = products.slice(0, 6);
          }

          const filteredProducts = filterSubscriptionEnabledItems
            ? products.filter((product) =>
                product.tags.includes('SubscriptionEnabled')
              )
            : products;

          return (
            <Slider
              isBlogPost={isBlogPost}
              sliderSize={sliderSize}
              itemSize={itemSize}
              elementNameTracking={
                elementNameTracking ?? [
                  'Collection Section Bestseller',
                  collection,
                ]
              }
            >
              {filteredProducts
                .filter((p) => p.availableForSale)
                .map((product, index: number) => {
                  return (
                    <ProductImpressionsTracker
                      collectionHandle={collection}
                      element={
                        (elementNameTracking && elementNameTracking[0]) ??
                        'Collection Section Bestseller'
                      }
                      key={`${product.id}-${product.promoteVariantId}`}
                      list={val.collection.title}
                      position={index}
                      product={product}
                      trackingGroupName={collection}
                    >
                      <div className="flex snap-center pb-4 lg:snap-start">
                        <ProductCard
                          collectionTitle={val.collection.title}
                          position={index}
                          product={product}
                          starSize={
                            sliderSize === 'large' || !isMobile
                              ? 'medium'
                              : 'small'
                          }
                          type={
                            sliderSize === 'large'
                              ? CARD_TYPES.SLIDER_LARGE
                              : CARD_TYPES.SLIDER
                          }
                          {...(onTileClick
                            ? {
                                onTileClick: () => {
                                  onTileClick(product);
                                },
                              }
                            : {})}
                        />
                      </div>
                    </ProductImpressionsTracker>
                  );
                })}
              {lastSlide && (
                <div className="flex snap-center pb-4">{lastSlide}</div>
              )}
            </Slider>
          );
        }}
      </CollectionContext.Consumer>
    </CollectionProvider>
  );
};

export default CollectionSectionBestseller;
