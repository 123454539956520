import cn from 'classnames';
import CarouselSelector from 'components/carousel-selector';
import useSlider from 'hooks/slider/use-slider';
import debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import useScrollTracking from 'tracking/use-scroll-tracking';
import ArrowButton from './arrow-button';
import styles from './silder.module.css';

interface Props {
  displayDots?: boolean;
  isBlogPost?: boolean;
  itemSize?: 'Normal' | 'Large' | undefined;
  elementNameTracking?: string[];
  sliderSize?: 'default' | 'medium' | 'large';
}

/**
 * Product slider component.
 * enableOnMobile is used to show the arrows on mobile
 * isBlogPost is used to apply a different style for the slider
 */
const Slider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  isBlogPost = false,
  sliderSize = 'default',
  itemSize = 'Normal',
  elementNameTracking,
}) => {
  const childrenElements = React.Children.toArray(children);
  const { displayedArrows, ref, slide, slideTo } = useSlider(children);
  const [currentSlide, setCurrentSlide] = useState(0);

  useScrollTracking(ref, elementNameTracking);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentSlide === childrenElements.length - 1) return;
      slide('left');
      setCurrentSlide(currentSlide + 1);
    },
    onSwipedRight: () => {
      if (currentSlide === 0) return;
      slide('right');
      setCurrentSlide(currentSlide - 1);
    },
    swipeDuration: 500,
    preventScrollOnSwipe: true,
  });

  useEffect(() => {
    const node = ref.current;

    const handleScroll = debounce(() => {
      if (node && sliderSize === 'default') {
        const { scrollLeft, scrollWidth, clientWidth } = node;
        const eachSlideWidth = scrollWidth / childrenElements.length;
        let currentSlideIndex = Math.round(scrollLeft / eachSlideWidth);

        if (scrollLeft + clientWidth >= scrollWidth) {
          currentSlideIndex = childrenElements.length - 1;
        }

        setCurrentSlide(currentSlideIndex);
      }
    }, 100);

    if (node) {
      node.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (node) {
        node.removeEventListener('scroll', handleScroll);
      }
    };
  }, [childrenElements.length, ref, sliderSize]);

  const content = (
    <div
      data-test="slider-content"
      className={cn(
        'grid snap-x snap-mandatory grid-flow-col gap-3 overflow-x-auto overflow-y-hidden lg:gap-5',
        isBlogPost && 'lg:auto-cols-[calc(25%-8px)]',
        isBlogPost && itemSize === 'Large' && '!auto-cols-[85%]',
        sliderSize === 'large' &&
          /** @TODO : the percentage should be adjusted when new card is available */
          'auto-cols-[calc(80%)] lg:auto-cols-[calc(33%-10px)]',
        sliderSize === 'medium' &&
          'auto-cols-[calc(75%)] lg:auto-cols-[calc(33%-10px)]',
        sliderSize === 'default' &&
          'auto-cols-[calc(50%-6px)] lg:auto-cols-[calc(25%-16px)]',
        styles.scrollbarHide
      )}
      ref={ref}
    >
      {children}
    </div>
  );

  const contentWrapper =
    sliderSize === 'large' ? (
      <div data-test="slider-content-wrapper" {...handlers}>
        {content}
      </div>
    ) : (
      <div
        data-test="slider-content-wrapper"
        className="min-h-[349px] lg:min-h-[424px]"
      >
        {content}
      </div>
    );

  return (
    <div data-test="slider-container" className="relative">
      <ArrowButton
        data-test="slider-arrow-button-left"
        currentSlide={currentSlide}
        direction="left"
        displayedArrow={displayedArrows.left}
        setCurrentSlide={setCurrentSlide}
        slide={slide}
      />

      {contentWrapper}

      <ArrowButton
        data-test="slider-arrow-button-right"
        currentSlide={currentSlide}
        direction="right"
        displayedArrow={displayedArrows.right}
        setCurrentSlide={setCurrentSlide}
        slide={slide}
      />

      {childrenElements.length > 1 && (
        <CarouselSelector
          data-test="slider-carousel-selector"
          className="lg:hidden"
          onClick={(index) => {
            setCurrentSlide(index);
            slideTo(index);
          }}
          selectedSlideIndex={currentSlide}
          slidesLength={childrenElements.length}
        />
      )}
    </div>
  );
};

export default Slider;
