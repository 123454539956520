import cn from 'classnames';
import IconChevronLeft from 'src/assets/icons/chevron-left.svg';
import IconChevronRight from 'src/assets/icons/chevron-right.svg';

interface Props {
  currentSlide: number;
  direction: 'left' | 'right';
  displayedArrow: boolean;
  slide: (direction: 'left' | 'right') => void;
  setCurrentSlide: React.Dispatch<React.SetStateAction<number>>;
}

/**
 * Arrow Buttons component used in out custom slider component.
 */
const ArrowButton: React.FC<Props> = ({
  currentSlide,
  direction,
  displayedArrow,
  setCurrentSlide,
  slide,
}) => {
  const isLeft = direction === 'left';
  const icon = isLeft ? (
    <IconChevronLeft className="w-4" />
  ) : (
    <IconChevronRight className="w-4" />
  );

  const slideDirection = isLeft ? 'right' : 'left';
  const updateCurrentSlide = isLeft
    ? () => setCurrentSlide(currentSlide - 1)
    : () => setCurrentSlide(currentSlide + 1);

  if (!displayedArrow) return null;

  return (
    <button
      className={cn(
        isLeft ? '-left-5' : '-right-5',
        'absolute top-1/2 z-10 hidden h-[42px] w-[42px] -translate-y-1/2 items-center justify-center rounded-full bg-gray-50 p-3 text-gray-600 shadow-sm focus:outline-none lg:block'
      )}
      onClick={() => {
        slide(slideDirection);
        updateCurrentSlide();
      }}
    >
      {icon}
    </button>
  );
};

export default ArrowButton;
