import cn from 'classnames';
import LazyMotionWrapper from 'components/lazy-motion-wrapper';
import { m } from 'framer-motion';
import React from 'react';

interface Props {
  className?: string;
  slidesLength: number;
  selectedSlideIndex: number;
  withAnimation?: boolean;
  onClick: (index: number) => void;
}

/**
 * CarouselSelector component is used to display the dots for the carousel / slider to select the current slide.
 */
const CarouselSelector: React.FC<Props> = ({
  className,
  slidesLength,
  selectedSlideIndex,
  onClick,
}) => {
  if (slidesLength === 1 || slidesLength > 20) return null;

  const sildes = Array.from({ length: slidesLength });

  return (
    <LazyMotionWrapper>
      <div
        className={cn('flex items-center justify-center gap-[6px]', className)}
      >
        {sildes.map((sildes, index) => (
          <m.button
            key={index}
            animate={{
              backgroundColor:
                selectedSlideIndex === index ? '#19335A' : '#B4BDCC',
              width: selectedSlideIndex === index ? 18 : 6,
            }}
            className="h-1.5 rounded-full focus:outline-none"
            onClick={() => onClick(index)}
            transition={{
              backgroundColor: { duration: 0.4, ease: 'easeInOut' },
              width: {
                damping: 20,
                duration: 0.2,
                stiffness: 500,
                type: 'spring',
              },
            }}
          />
        ))}
      </div>
    </LazyMotionWrapper>
  );
};

export default CarouselSelector;
