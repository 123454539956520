import { useEffect, useRef } from 'react';
import { gtm, scrollElement } from 'tracking';

/**
 * Tracks horizontal scroll events on a given element.
 */
const useScrollTracking = (
  scrollRef: React.RefObject<HTMLDivElement>,
  elementNameTracking: string[] | undefined
): void => {
  const hasScrolledRef = useRef(false);
  const trackedElementsRef = useRef(new Set<string>());

  useEffect(() => {
    const ref = scrollRef.current;
    if (!ref || !elementNameTracking) return;

    const handleHorizontalScroll = (): void => {
      const scrollPosition = ref.scrollLeft;

      const elementsToTrack = elementNameTracking.join(' ');

      if (!hasScrolledRef.current && scrollPosition > 0) {
        hasScrolledRef.current = true;
        trackedElementsRef.current.add(elementsToTrack);
        gtm(scrollElement({ element: elementNameTracking }));
      }

      if (!trackedElementsRef.current.has(elementsToTrack)) {
        trackedElementsRef.current.add(elementsToTrack);
        gtm(scrollElement({ element: elementsToTrack }));
      }
    };

    ref.addEventListener('scroll', handleHorizontalScroll);

    return () => ref.removeEventListener('scroll', handleHorizontalScroll);
  }, [scrollRef, elementNameTracking]);

  return;
};

export default useScrollTracking;
